import {mapState,mapActions} from 'vuex';
import Vue from 'vue';
import { Picker } from 'vant';
import { Popup } from 'vant';
import { Calendar } from 'vant';
import { Step, Steps } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Uploader } from 'vant';

Vue.use(Uploader);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Calendar);
Vue.use(Popup);
Vue.use(Picker);
export default {
  name: 'application',
  components: {},
  data() {
    return {
      showdate:false,
      showdate1:false,
      showdate3:false,
      isfalse:false,
      active: 1,
      date:'',
      date1:'',
      date3:'',
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2030, 0, 31),
      radio1:'0',
      radio3:'0',
      icon: {
        normal: require('../../assets/image/nocheck.png'),
        active: require('../../assets/image/checkes.png'),
      },
      columns:["发票","病例","药品","资料(身份证，申请书等证明材料)"],
      show:false,
      value:'',
    };
  },
  methods: {
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm4(date) {
      this.showdate = false;
      this.date = this.formatDate(date);
    },
    onConfirm1(date) {
      this.showdate1 = false;
      this.date1 = this.formatDate(date);
    },
    onConfirm3(date) {
      this.showdate3 = false;
      this.date3 = this.formatDate(date);
    },
    onChange2(event) {
    },
    onChange3(event){

    },
    onConfirm(value, index) {
      this.show=false
      this.value=value

    },
    onChange(picker, value, index) {

    },
    onCancel() {
      this.show=false
    },

  },
};
